<template>
    <div class="illustration-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 240">
            <defs>
                <filter id="shadow">
                    <feDropShadow dx="0" dy="2" stdDeviation="3" flood-opacity="0.1" />
                </filter>
                <!-- Glow effect for active nodes -->
                <filter id="glow">
                    <feGaussianBlur stdDeviation="2" result="coloredBlur" />
                    <feMerge>
                        <feMergeNode in="coloredBlur" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>

            <!-- Main Card -->
            <rect x="20" y="20" width="260" height="200" rx="12" fill="white" filter="url(#shadow)" />

            <!-- Header -->
            <text x="35" y="45" font-family="IBM Plex Sans, sans-serif" font-size="14" fill="#111827" font-weight="600">
                AutoML Pipeline
            </text>

            <!-- Pipeline Stages -->
            <g transform="translate(45, 70)">
                <!-- Stages -->
                <g transform="translate(0, 0)">
                    <!-- Initialization -->
                    <circle cx="20" cy="20" r="6" fill="#10b981" filter="url(#glow)" />
                    <text x="20" y="45" font-family="IBM Plex Sans, sans-serif" font-size="10" fill="#374151"
                        text-anchor="middle">
                        Init
                    </text>

                    <!-- Data Prep -->
                    <circle cx="80" cy="20" r="6" fill="#10b981" />
                    <text x="80" y="45" font-family="IBM Plex Sans, sans-serif" font-size="10" fill="#374151"
                        text-anchor="middle">
                        Prep
                    </text>

                    <!-- Training -->
                    <circle cx="140" cy="20" r="6" fill="#10b981" />
                    <text x="140" y="45" font-family="IBM Plex Sans, sans-serif" font-size="10" fill="#374151"
                        text-anchor="middle">
                        Train
                    </text>

                    <!-- Completion -->
                    <circle cx="200" cy="20" r="6" fill="#8b5cf6" />
                    <text x="200" y="45" font-family="IBM Plex Sans, sans-serif" font-size="10" fill="#374151"
                        text-anchor="middle">
                        Complete
                    </text>

                    <!-- Connecting Lines -->
                    <line x1="26" y1="20" x2="74" y2="20" stroke="#e5e7eb" stroke-width="2" />
                    <line x1="86" y1="20" x2="134" y2="20" stroke="#e5e7eb" stroke-width="2" />
                    <line x1="146" y1="20" x2="194" y2="20" stroke="#e5e7eb" stroke-width="2" />
                </g>

                <!-- Model Types -->
                <g transform="translate(0, 80)">
                    <text x="110" y="0" font-family="IBM Plex Sans, sans-serif" font-size="11" fill="#6b7280"
                        text-anchor="middle">
                        Available Models
                    </text>

                    <rect x="10" y="10" width="200" height="50" rx="6" fill="#f8f9fa" />
                    <text x="20" y="30" font-family="IBM Plex Sans, sans-serif" font-size="10" fill="#374151">
                        • Random Forest
                    </text>
                    <text x="20" y="45" font-family="IBM Plex Sans, sans-serif" font-size="10" fill="#374151">
                        • Linear Regression
                    </text>
                    <text x="120" y="30" font-family="IBM Plex Sans, sans-serif" font-size="10" fill="#374151">
                        • Decision Tree
                    </text>
                    <text x="120" y="45" font-family="IBM Plex Sans, sans-serif" font-size="10" fill="#374151">
                        • Support Vector
                    </text>
                </g>
            </g>
        </svg>
    </div>
</template>
