<template>
    <div class="illustration-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 240" preserveAspectRatio="xMidYMid meet">
            <defs>
                <linearGradient id="cardGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" style="stop-color: #f8fafc; stop-opacity: 1" />
                    <stop offset="100%" style="stop-color: #f1f5f9; stop-opacity: 1" />
                </linearGradient>
                <filter id="shadow">
                    <feDropShadow dx="0" dy="2" stdDeviation="3" flood-opacity="0.1" />
                </filter>
            </defs>

            <!-- Main Container -->
            <rect x="20" y="20" width="260" height="200" rx="12" fill="white" filter="url(#shadow)" />

            <!-- Request Section -->
            <g transform="translate(40, 40)">
                <!-- Request Header -->
                <rect width="100" height="16" rx="4" fill="#f1f5f9" />
                <text x="10" y="12" font-family="'IBM Plex Mono', monospace" font-size="10" fill="#64748b">
                    POST /predict
                </text>

                <!-- Request Body -->
                <g transform="translate(0, 24)">
                    <rect width="200" height="60" rx="6" fill="#f8fafc" />
                    <!-- Code-like content -->
                    <text x="10" y="20" font-family="'IBM Plex Mono', monospace" font-size="9" fill="#3b82f6">
                        "api_key"
                    </text>
                    <text x="65" y="20" font-family="'IBM Plex Mono', monospace" font-size="9" fill="#64748b">
                        : "f524d99..."
                    </text>
                    <text x="10" y="40" font-family="'IBM Plex Mono', monospace" font-size="9" fill="#3b82f6">
                        "input_data"
                    </text>
                    <text x="75" y="40" font-family="'IBM Plex Mono', monospace" font-size="9" fill="#64748b">
                        : {...}
                    </text>
                </g>
            </g>

            <!-- Arrow -->
            <g transform="translate(140, 130)">
                <line x1="0" y1="0" x2="20" y2="0" stroke="#e2e8f0" stroke-width="2" />
                <polygon points="20,-4 28,0 20,4" fill="#e2e8f0" />
            </g>

            <!-- Response Section -->
            <g transform="translate(40, 140)">
                <!-- Response Body -->
                <rect width="200" height="60" rx="6" fill="#f8fafc" />
                <!-- Response Content -->
                <text x="10" y="20" font-family="'IBM Plex Mono', monospace" font-size="9" fill="#3b82f6">
                    "predictions"
                </text>
                <text x="75" y="20" font-family="'IBM Plex Mono', monospace" font-size="9" fill="#64748b">
                    : [0.85, ...]
                </text>
                <text x="10" y="40" font-family="'IBM Plex Mono', monospace" font-size="9" fill="#3b82f6">
                    "confidence"
                </text>
                <text x="70" y="40" font-family="'IBM Plex Mono', monospace" font-size="9" fill="#64748b">
                    : [0.92, ...]
                </text>
            </g>
        </svg>
    </div>
</template>
