<template>
  <div class="dark-mode-container">
    <button @click="toggleDarkMode" class="toggle-button" :class="{ 'is-dark': darkModeStatus === 'enabled' }"
      aria-label="Toggle dark mode">
      <div class="icon-container">
        <SunIcon class="sun-icon" />
        <MoonIcon class="moon-icon" />
      </div>
      <div class="toggle-backdrop"></div>
    </button>
    <p class="border-radius-status-text">Toggle color mode</p>
  </div>
</template>

<script>
import { MoonIcon, SunIcon } from "@heroicons/vue/24/outline";
import axios from "@/utils/http";

export default {
  name: "ToggleDarkMode",
  components: {
    MoonIcon,
    SunIcon,
  },
  props: {
    darkMode: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      darkModeStatus: this.darkMode ? "enabled" : "disabled",
    };
  },
  methods: {
    async toggleDarkMode() {
      this.darkModeStatus = this.darkModeStatus === "enabled" ? "disabled" : "enabled";
      this.applyDarkMode();
      this.saveDarkModePreference();

      try {
        await axios.post("/user/toggle_dark_mode", {
          dark_mode_enabled: this.darkModeStatus === "enabled",
        });
      } catch (error) {
        console.error("Error toggling dark mode:", error);
      }
    },
    applyDarkMode() {
      if (this.darkModeStatus === "enabled") {
        document.body.classList.add("dark-mode");
      } else {
        document.body.classList.remove("dark-mode");
      }
    },
    saveDarkModePreference() {
      localStorage.setItem("darkMode", this.darkModeStatus === "enabled");
    },
    loadDarkModePreference() {
      const storedPreference = localStorage.getItem("darkMode");
      if (storedPreference !== null) {
        this.darkModeStatus = storedPreference === "true" ? "enabled" : "disabled";
      }
      this.applyDarkMode();
    },
  },
  watch: {
    darkMode(newVal) {
      this.darkModeStatus = newVal ? "enabled" : "disabled";
      this.applyDarkMode();
    },
  },
  created() {
    this.loadDarkModePreference();
  },
};
</script>

<style scoped>
.dark-mode-container {
  display: inline-flex;
  align-items: center;
}

.toggle-button {
  position: relative;
  width: 3.5rem;
  height: 2rem;
  border: none;
  background: transparent;
  cursor: pointer;
  border-radius: 9999px;
  padding: 0;
  overflow: hidden;
  transform: scale(1);
  transition: transform 0.2s ease;
}

.toggle-button:hover {
  transform: scale(1.05);
}

.toggle-button:active {
  transform: scale(0.97);
}

.toggle-backdrop {
  position: absolute;
  inset: 0;
  background: linear-gradient(to right, #f97316, #fbbf24);
  border-radius: 9999px;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.is-dark .toggle-backdrop {
  background: linear-gradient(to right, #3b82f6, #8b5cf6);
}

.icon-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.35rem;
  z-index: 1;
}

.sun-icon,
.moon-icon {
  width: 1.25rem;
  height: 1.25rem;
  color: white;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.sun-icon {
  opacity: 1;
  transform: translateX(0) rotate(0deg);
}

.moon-icon {
  opacity: 0;
  transform: translateX(2rem) rotate(90deg);
}

.is-dark .sun-icon {
  opacity: 0;
  transform: translateX(-2rem) rotate(-90deg);
}

.is-dark .moon-icon {
  opacity: 1;
  transform: translateX(0) rotate(0deg);
}

/* Optional: Add subtle shadow for depth */
.toggle-button::after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 9999px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1) inset;
  z-index: 2;
}

/* Dark mode specific styles */
:global(.dark-mode) .toggle-button::after {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2) inset;
}
</style>
