<template>
    <div class="action-links" role="menu">
        <div v-for="link in actionLinks" :key="link.path" class="action-link" role="menuitem"
            @click="navigateTo(link.path)">
            <component :is="link.icon" class="action-icon" />
            <span>{{ link.text }}</span>
        </div>
    </div>
</template>

<script setup>
import {
    DocumentIcon,
    DocumentCheckIcon,
    WrenchScrewdriverIcon,
} from "@heroicons/vue/24/outline";

const actionLinks = [
    { path: "/upload_dataset", text: "Upload Dataset", icon: DocumentIcon },
    { path: "/preprocess-dataset", text: "Preprocess Data", icon: DocumentCheckIcon },
    { path: "/build_pipeline", text: "Build Pipeline", icon: WrenchScrewdriverIcon },
];

const navigateTo = (path) => {
    window.location.href = path; // Redirect using window.location.href
};
</script>
