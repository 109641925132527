<template>
    <div class="illustration-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 240" preserveAspectRatio="xMidYMid meet">
            <defs>
                <linearGradient id="cardGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" style="stop-color: #f8fafc; stop-opacity: 1" />
                    <stop offset="100%" style="stop-color: #f1f5f9; stop-opacity: 1" />
                </linearGradient>
                <filter id="shadow">
                    <feDropShadow dx="0" dy="2" stdDeviation="3" flood-opacity="0.1" />
                </filter>
            </defs>

            <!-- Main Container -->
            <rect x="20" y="20" width="260" height="200" rx="12" fill="white" filter="url(#shadow)" />

            <!-- Left Section: Projects -->
            <g transform="translate(40, 40)">
                <!-- Projects Header -->
                <text x="0" y="16" font-family="'IBM Plex Sans', sans-serif" font-size="14" fill="#1e293b"
                    font-weight="500">
                    Projects
                </text>

                <!-- New Project Box -->
                <rect x="0" y="30" width="100" height="32" rx="6" fill="#f8fafc" />
                <!-- Plus Symbol -->
                <line x1="45" y1="46" x2="55" y2="46" stroke="#94a3b8" stroke-width="2" stroke-linecap="round" />
                <line x1="50" y1="41" x2="50" y2="51" stroke="#94a3b8" stroke-width="2" stroke-linecap="round" />

                <!-- Project 1 -->
                <g transform="translate(0, 70)">
                    <rect width="100" height="32" rx="6" fill="#f8fafc" />
                    <circle cx="16" cy="16" r="4" fill="#10b981" />
                    <text x="32" y="20" font-family="'IBM Plex Sans', sans-serif" font-size="12" fill="#475569">
                        Analysis
                    </text>
                </g>

                <!-- Project 2 -->
                <g transform="translate(0, 110)">
                    <rect width="100" height="32" rx="6" fill="#f8fafc" />
                    <circle cx="16" cy="16" r="4" fill="#94e2cd" />
                    <text x="32" y="20" font-family="'IBM Plex Sans', sans-serif" font-size="12" fill="#475569">
                        new
                    </text>
                </g>
            </g>

            <!-- Right Section: Quick Actions -->
            <g transform="translate(160, 40)">
                <!-- Quick Actions Header -->
                <text x="0" y="16" font-family="'IBM Plex Sans', sans-serif" font-size="14" fill="#1e293b"
                    font-weight="500">
                    Quick Actions
                </text>
                <!-- Action Items -->
                <rect x="0" y="30" width="80" height="16" rx="4" fill="#f1f5f9" />
                <rect x="0" y="54" width="80" height="16" rx="4" fill="#f1f5f9" />
                <rect x="0" y="78" width="80" height="16" rx="4" fill="#f1f5f9" />
            </g>

            <!-- Bottom Section: Notifications -->
            <g transform="translate(40, 160)">
                <!-- Notification Area -->
                <rect x="0" y="0" width="220" height="40" rx="8" fill="#f1f5f9" />
                <circle cx="20" cy="20" r="4" fill="#3b82f6" />
                <rect x="35" y="16" width="140" height="8" rx="4" fill="#e2e8f0" />
            </g>
        </svg>
    </div>
</template>
