<template>
    <div>
        <h2>Platform</h2>
        <section v-for="(category, index) in categories" :key="index" class="section">
            <header @click="toggleCategory(index)" class="header">
                <div class="header-content">
                    <component :is="category.icon" class="icon" />
                    <span class="category-name">{{ category.name }}</span>
                </div>
            </header>
            <transition name="slide-vertical">
                <ul v-if="openIndex === index" class="link-list">
                    <li v-for="(link, linkIndex) in category.subLinks" :key="linkIndex">
                        <a :href="link.url" class="link">{{ link.label }}</a>
                    </li>
                </ul>
            </transition>
        </section>
    </div>
</template>

<script>
import {
    ArrowPathRoundedSquareIcon,
    CubeIcon,
    EllipsisHorizontalCircleIcon as CogIcon,
    BriefcaseIcon,
} from "@heroicons/vue/24/outline";

export default {
    data() {
        return {
            openIndex: null,
            categories: [
                {
                    name: "Workspace",
                    icon: BriefcaseIcon,
                    subLinks: [
                        { label: "Projects", url: "/workspace" },
                        { label: "Dashboards", url: "/view-dashboards" },
                        { label: "Datasets", url: "/view-datasets" },
                        { label: "Preprocessed Data", url: "/view-preprocessed-datasets" },
                    ],
                },
                {
                    name: "Models",
                    icon: CubeIcon,
                    subLinks: [
                        { label: "Models", url: "/models" },
                        { label: "Predictions", url: "/predictions" },
                        { label: "Model Deployment", url: "/model-deployments" },
                        { label: "Monitoring", url: "/monitoring" },
                    ],
                },
                {
                    name: "Pipelines",
                    icon: ArrowPathRoundedSquareIcon,
                    subLinks: [
                        { label: "Pipelines", url: "/pipelines" },
                        { label: "Magic Processes", url: "/view_magic_processes" },
                    ],
                },
                {
                    name: "More",
                    icon: CogIcon,
                    subLinks: [
                        { label: "Support", url: "/support" },
                        { label: "Learning", url: "/learning" },
                        { label: "Documentation", url: "/platform/documentation" },
                        { label: "Settings", url: "/user/settings" },
                        { label: "Subscription", url: "/pricing" },
                    ],
                },
            ],
        };
    },
    methods: {
        toggleCategory(index) {
            this.$emit("category-clicked"); // Notify parent
            this.openIndex = this.openIndex === index ? null : index; // Toggle category
        },
    },
};
</script>

<style>
.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.section {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 1rem;
}

.header {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.header-content {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.icon {
    width: 24px;
    height: 24px;
}

.link-list {
    margin-top: 0.5rem;
    padding-left: 1.5rem;
}

.link {
    color: #007bff;
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
}
</style>
