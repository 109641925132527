<template>
    <div class="illustration-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 240" preserveAspectRatio="xMidYMid meet" width="100%"
            height="100%" class="illustration-svg">
            <defs>
                <linearGradient id="cardGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" style="stop-color: #f8fafc; stop-opacity: 1" />
                    <stop offset="100%" style="stop-color: #f1f5f9; stop-opacity: 1" />
                </linearGradient>
                <filter id="shadow">
                    <feDropShadow dx="0" dy="2" stdDeviation="3" flood-opacity="0.1" />
                </filter>
            </defs>

            <!-- Main Card -->
            <rect x="20" y="20" width="260" height="200" rx="12" fill="white" filter="url(#shadow)" />

            <!-- Header Section -->
            <g transform="translate(40, 40)">
                <!-- Model Name as plain text -->
                <text x="0" y="16" font-family="'IBM Plex Sans', sans-serif" font-size="14" fill="#1e293b"
                    font-weight="500">
                    Random Forest Classifier
                </text>

                <!-- Score Badge -->
                <g transform="translate(180, 0)">
                    <rect width="40" height="24" rx="12" fill="#10b981" />
                    <text x="20" y="16" font-family="'IBM Plex Sans', sans-serif" font-size="12" fill="white"
                        text-anchor="middle">
                        99
                    </text>
                </g>
            </g>

            <!-- Action Buttons -->
            <g transform="translate(40, 80)">
                <!-- Deploy Button -->
                <rect width="100" height="28" rx="14" fill="#1e293b" />
                <text x="50" y="18" font-family="'IBM Plex Sans', sans-serif" font-size="11" fill="white"
                    text-anchor="middle">
                    DEPLOY
                </text>

                <!-- Predict Button -->
                <g transform="translate(120, 0)">
                    <rect width="80" height="28" rx="14" fill="#3b82f6" />
                    <text x="40" y="18" font-family="'IBM Plex Sans', sans-serif" font-size="11" fill="white"
                        text-anchor="middle">
                        PREDICT
                    </text>
                </g>
            </g>

            <!-- Feature Bars (Simplified) -->
            <g transform="translate(40, 128)">
                <!-- Top Feature -->
                <rect x="0" y="0" width="200" height="8" rx="4" fill="#3b82f6" opacity="0.8" />

                <!-- Second Feature -->
                <rect x="0" y="16" width="160" height="8" rx="4" fill="#3b82f6" opacity="0.6" />

                <!-- Third Feature -->
                <rect x="0" y="32" width="120" height="8" rx="4" fill="#3b82f6" opacity="0.4" />

                <!-- Fourth Feature -->
                <rect x="0" y="48" width="80" height="8" rx="4" fill="#3b82f6" opacity="0.2" />
            </g>
        </svg>
    </div>
</template>

<style scoped>
.illustration-wrapper {
    width: 100%;
    max-width: 400px;
    aspect-ratio: 300/240;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.illustration-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
}

.card-shadow {
    filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
}

.panel-shadow {
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.05));
}
</style>
