<template>
    <div>
        <h2>Recent Shortcuts</h2>
        <transition-group tag="ul" name="shortcut" class="shortcut-list">
            <li v-for="shortcut in sortedLinks" :key="shortcut.link" class="shortcut-item"
                @mouseenter="hoveredShortcut = shortcut.link" @mouseleave="hoveredShortcut = null">
                <a :href="shortcut.link">
                    <template v-if="shortcut.pinned">
                        <StarOutlineIcon />
                    </template>
                    <template v-else>
                        <ClockIcon />
                    </template>
                    <span class="shortcut-label">
                        {{ shortcut.label || shortcut.link }}
                    </span>
                </a>
                <button v-if="hoveredShortcut === shortcut.link" @click="togglePin(shortcut.link, shortcut.pinned)">
                    <template v-if="shortcut.pinned">
                        <XMarkIcon style="
                width: 20px;
                height: 20px;
                color: var(--text-color-muted);
                margin-left: 8px;
              " />
                    </template>
                    <template v-else>
                        <StarOutlineIcon style="
                width: 20px;
                height: 20px;
                color: var(--text-color);
                margin-left: 8px;
              " />
                    </template>
                </button>
            </li>
        </transition-group>
    </div>
</template>
<script>
import { ref, computed, onMounted } from "vue";
import axios from "@/utils/http.js"; // Import Axios instance
import { XMarkIcon } from "@heroicons/vue/24/solid"; // Import Heroicons V2
import { BookmarkIcon as StarOutlineIcon, ClockIcon } from "@heroicons/vue/24/outline";

export default {
    name: "RecentShortcuts",
    components: {
        StarOutlineIcon,
        ClockIcon,
        XMarkIcon,
    },
    setup() {
        const links = ref([]);
        const hoveredShortcut = ref(null);

        const fetchShortcuts = async () => {
            try {
                const response = await axios.get("/user/get_shortcuts");
                const data = response.data;
                links.value = data.shortcuts;
            } catch (error) {
                console.error("Error fetching shortcuts:", error);
            }
        };

        const togglePin = async (link, isPinned) => {
            const endpoint = isPinned ? "/user/unpin_shortcut" : "/user/pin_shortcut";

            try {
                await axios.post(endpoint, { link });
                const shortcut = links.value.find((item) => item.link === link);
                if (shortcut) {
                    shortcut.pinned = !isPinned;
                }
            } catch (error) {
                console.error(`Error ${isPinned ? "unpinning" : "pinning"} shortcut:`, error);
            }
        };

        const sortedLinks = computed(() =>
            links.value.slice().sort((a, b) => b.pinned - a.pinned)
        );

        onMounted(() => {
            fetchShortcuts();
        });

        return {
            links,
            hoveredShortcut,
            togglePin,
            sortedLinks,
        };
    },
};
</script>
