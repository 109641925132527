<template>
    <div v-if="open" class="notifications-dropdown" role="menu">
        <div v-if="notifications.length > 0">
            <div v-for="notification in notifications" :key="notification._id" class="notification-item"
                role="menuitem">
                <p class="notification-message" :class="{ unread: !notification.read }">
                    {{ notification.message }}
                </p>
                <div class="notifcation-links">
                    <button class="notification-action" @click="markAsRead(notification._id)">
                        Mark as Read
                    </button>
                    <button class="notification-destination"
                        @click="goToDestination(notification.destination, notification._id)">
                        Go to Destination
                    </button>
                </div>
            </div>
        </div>
        <div v-else>
            <p class="no-notifications">No new notifications</p>
        </div>
    </div>
</template>

<script setup>
import { defineProps } from "vue";
import axios from "@/utils/http"; // Import your Axios setup

defineProps({
    open: {
        type: Boolean,
        required: true,
    },
    notifications: {
        type: Array,
        required: true,
    },
});

const markAsRead = async (id) => {
    try {
        const response = await axios.post(`/user/notifications/mark_as_read/${id}`);
        if (response.status === 200) {
            console.log("Notification marked as read");
        } else {
            console.error("Failed to mark notification as read");
        }
    } catch (error) {
        console.error("Error marking notification as read:", error);
    }
};

const goToDestination = async (destination, id) => {
    try {
        // Mark the notification as read before navigating
        await markAsRead(id);
        window.location.href = destination; // Redirect to the destination
    } catch (error) {
        console.error("Error navigating to destination:", error);
    }
};
</script>
