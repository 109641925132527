<template>
  <NavBar />
  <div>
    <!-- Hero Section -->
    <section class="hero-section index base-style">
      <!-- Gradient Canvas as background -->
      <div class="platform-container">
        <div class="hero-content">
          <div class="text-content">
            <h4>Launched January 2025</h4>
            <h1>Empower Your Business with No-Code AI</h1>
            <p>
              Revolutionize the way you approach machine learning. Build models, train
              pipelines, and predict outcomes—all with the power of no-code automation.
            </p>
            <button class="submit-button-cls" @click="navigateToRegister">Sign Up</button>
          </div>
          <div class="hero-image-container">
            <DataPreprocessingVisual7 />
            <div class="cradle-shape">
              <GradientCanvas class="gradient-canvas" />
            </div>
          </div>
        </div>
        <!-- Static High-Tech Design Elements -->
        <div class="static-grid"></div>
        <div class="static-circuit-lines"></div>
      </div>
    </section>

    <!-- Card Section with Icons -->
    <section class="card-section variant3 dashed">
      <div class="platform-container">
        <header>
          <h4>ML Clever Pipelines</h4>
          <h2>Powerful Pipeline Features</h2>
          <p>
            Discover the capabilities that make building and managing AI pipelines
            efficient, reliable, and highly customizable.
          </p>
        </header>
        <div class="card-content">
          <div class="card">
            <CogIcon class="card-icon" />
            <h3>Advanced Data Preprocessing</h3>
            <p>
              Transform raw data into a model-ready format with customizable preprocessing
              options. Achieve clean, consistent data flows every time without risking
              data leakage.
            </p>
          </div>
          <div class="card">
            <SparklesIcon class="card-icon" />
            <h3>Adaptive Model Training</h3>
            <p>
              Automatically fine-tune and train models tailored to your pipeline's needs,
              ensuring you reach optimal performance with minimal effort.
            </p>
          </div>
          <div class="card">
            <ArrowPathIcon class="card-icon" />
            <h3>Effortless Pipeline Reuse</h3>
            <p>
              Repurpose and adapt your existing pipelines to new datasets seamlessly,
              making it easy to scale your results and refine your models iteratively.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Platform Content Section: Adaptive Model Creation -->
    <section class="platform-content-section base-style">
      <div class="platform-container">
        <div class="platform-content">
          <div class="text-content">
            <h4>Adaptive Model Creation</h4>
            <h2>Precision Meets Simplicity</h2>
            <p>
              Build models that adapt to your financial challenges. ML Clever empowers you
              to create machine learning solutions with features designed to:
            </p>
            <div class="stat">
              <ul>
                <li>
                  Choose from advanced algorithms, including Support Vector Machines,
                  Decision Trees, and Deep Learning Architectures.
                </li>
                <li>
                  Optimize model performance with intuitive parameter adjustments for
                  unparalleled accuracy.
                </li>
                <li>
                  Compare and iterate on multiple configurations to ensure your model
                  aligns with your goals.
                </li>
              </ul>
            </div>
            <p>
              From novice analysts to seasoned experts, our platform transforms complexity
              into a seamless, guided experience tailored to your needs.
            </p>
            <button class="submit-button-cls" @click="navigateToRegister">Sign Up</button>
          </div>
          <div class="image-container">
            <DataPreprocessingVisual />
          </div>
        </div>
      </div>
    </section>

    <section class="platform-content-section base-style">
      <div class="platform-container">
        <div class="platform-content">
          <div class="text-content">
            <h4>Customize Every Insight</h4>
            <h2>Build Dashboards That Fit Your Workflow</h2>
            <p>
              ML Clever’s custom dashboards let you showcase the metrics, visualizations,
              and KPIs that matter most to you. Design a flexible interface that adapts to
              your unique workflow, with tools like charts, graphs, tables, and filters
              for effective data presentation.
            </p>
            <p>
              Keep your insights fresh with real-time data, adjustable layouts, and export
              options to share views seamlessly with your team.
            </p>
            <p>
              Our interactive features—like sliders, dropdowns, and date pickers—let you
              dig into data easily, offering a visually intuitive experience that supports
              strategic decisions.
            </p>
            <button class="submit-button-cls" @click="navigateTo('/platform/dashboards')">
              Explore Dashboard Features
            </button>
          </div>
          <div class="image-container">
            <img
              src="/static/img/platform/dashboard2.png"
              alt="Custom Dashboard Interface"
            />
          </div>
        </div>
      </div>
    </section>

    <!-- Slideshow 1 Section -->
    <section class="slide-show-content-section full-width">
      <div class="platform-container">
        <div class="slide-show-content">
          <header>
            <h4>Revolutionizing Automation</h4>
            <h2>Unleashing AI Potential</h2>
            <p>
              Discover how ML Clever transforms industries with automation, optimization,
              scalability, and innovation.
            </p>
          </header>

          <div class="slider-container">
            <div class="slider-content">
              <!-- Slideshow 1 Images with Fade Animation -->
              <transition name="fade" mode="out-in">
                <div class="slider-images" :key="slideshow1.currentSlide">
                  <a
                    v-for="slide in slideshow1.slides"
                    :key="slide.id"
                    v-show="slideshow1.currentSlide === slide.id"
                    :href="slide.link || '#'"
                    class="slide"
                    :style="{
                      backgroundImage: `url(${slide.imgSrc})`,
                      '--slide-color': slide.color,
                      '--slide-color-before': slide.color_before,
                      '--slide-color-after': slide.color_after,
                      '--slide-color-fullwidth': slide.color_fullwidth,
                    }"
                  >
                    <div
                      class="overlay"
                      :style="{ backgroundColor: slide.bgColor }"
                    ></div>
                    <div class="slide-link">
                      <h4>{{ slide.title }}</h4>
                      <p>{{ slide.linkText }}</p>
                    </div>
                    <component :is="slide.icon" class="slide-icon left" />
                    <ArrowUpRightIcon class="slide-icon" />
                  </a>
                </div>
              </transition>
            </div>

            <!-- Slideshow 1 Navigation Buttons -->
            <div class="slider-buttons">
              <button
                v-for="slide in slideshow1.slides"
                :key="slide.id"
                :class="{ active: slideshow1.currentSlide === slide.id }"
                @click="changeSlideShow1(slide.id)"
              >
                {{ slide.buttonLabel }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Platform Content Section -->
    <section class="platform-content-section base-style">
      <div class="platform-container">
        <div class="platform-content">
          <div class="text-content">
            <h4>Real-Time Workflow</h4>
            <h2>Intuitive and Effective Predictive Insights</h2>
            <p>
              The real-time prediction workflow guides users through instant input and
              output, ensuring that no time is wasted in getting the answers you need. ML
              Clever handles the underlying complexities, letting you focus solely on the
              insights generated by your data.
            </p>
            <button class="submit-button-cls" @click="navigateTo('/auth/register')">
              Sign Up
            </button>
          </div>
          <div class="image-container">
            <img
              src="/static/img/platform/prediction_calculator.png"
              alt="Comprehensive Real-Time Prediction Dashboard"
            />
          </div>
        </div>
      </div>
    </section>

    <!-- Card Section with Images -->
    <section class="card-section variant2 stagger">
      <div class="platform-container">
        <header>
          <h4>Training Your Way</h4>
          <h2>Seamless Control and Automation</h2>
          <p>
            ML Clever provides the perfect balance of precision and efficiency. Whether
            you prefer deep customization or hands-free automation, our platform adapts to
            your workflow, ensuring optimal results.
          </p>
        </header>
        <div class="card-content">
          <div class="card-column card-left">
            <div class="card">
              <ModelVisual class="card-img blue" />
              <div class="card-text-container">
                <h3>Advanced Model Customization</h3>
                <p>
                  Take charge of every detail in your machine learning process. From model
                  selection to configuration, achieve precise results that align perfectly
                  with your data needs.
                </p>
                <p class="learn-more">Learn More</p>
              </div>
            </div>

            <div class="card">
              <WorkspaceVisual class="card-img green" />
              <div class="card-text-container">
                <h3>Smart Optimization Tools</h3>
                <p>
                  Use intuitive tools to fine-tune models for peak performance.
                  Effortlessly make adjustments that maximize your model's impact and
                  accuracy.
                </p>
                <p class="learn-more">Learn More</p>
              </div>
            </div>
          </div>
          <div class="card-column card-right">
            <div class="card">
              <ModelVisual2 class="card-img purple" />
              <div class="card-text-container">
                <h3>Effortless AutoML</h3>
                <p>
                  Let ML Clever handle the heavy lifting with automatic model training,
                  optimization, and deployment. Designed for users who value speed without
                  sacrificing accuracy.
                </p>
                <p class="learn-more">Learn More</p>
              </div>
            </div>
            <div class="card">
              <PredictVisual2 class="card-img orange" />
              <div class="card-text-container">
                <h3>Scalable Predictions</h3>
                <p>
                  Leverage your trained models for high-speed predictions, enabling
                  instant insights and empowering decision-making in real time.
                </p>
                <p class="learn-more">Learn More</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Slideshow 2 Section -->
    <section class="slide-show-content-section">
      <div class="platform-container">
        <div class="slide-show-content">
          <header>
            <h4>Finance Applications</h4>
            <h2>Transforming Finance with Machine Learning</h2>
            <p>
              Discover how ML Clever empowers financial analysts, institutions, and
              enterprises to unlock the full potential of data-driven insights, optimize
              decision-making, and revolutionize finance workflows.
            </p>
          </header>
          <div class="slider-container">
            <div class="slider-content">
              <!-- Slideshow 2 Text Content with Slide Animation -->
              <transition
                :name="slideshow2Direction === 'right' ? 'slide-left' : 'slide-right'"
                mode="out-in"
              >
                <div class="slider-column" :key="slideshow2.currentSlide">
                  <div v-if="slideshow2.currentSlide === 1" class="stat">
                    <h3>Market Trend Forecasting</h3>
                    <p>
                      Utilize predictive analytics to anticipate market movements, improve
                      portfolio strategies, and make data-backed investment decisions with
                      confidence.
                    </p>
                    <ChartLineIcon class="slide-icon left" />
                    <ArrowUpRightIcon class="slide-icon" />
                  </div>
                  <div v-if="slideshow2.currentSlide === 2" class="stat">
                    <h3>Risk Management</h3>
                    <p>
                      Identify potential risks using anomaly detection and machine
                      learning models, helping financial institutions mitigate exposure
                      and enhance decision-making.
                    </p>
                    <ShieldCheckIcon class="slide-icon left" />
                    <ArrowUpRightIcon class="slide-icon" />
                  </div>
                  <div v-if="slideshow2.currentSlide === 3" class="stat">
                    <h3>Fraud Detection</h3>
                    <p>
                      Detect fraudulent activities in real time with ML Clever’s advanced
                      algorithms, ensuring secure transactions and safeguarding financial
                      systems.
                    </p>
                    <LockClosedIcon class="slide-icon left" />
                    <ArrowUpRightIcon class="slide-icon" />
                  </div>
                  <div v-if="slideshow2.currentSlide === 4" class="stat">
                    <h3>Customer Personalization</h3>
                    <p>
                      Enhance customer experiences with machine learning-driven insights,
                      offering personalized financial products and tailored
                      recommendations.
                    </p>
                    <UserIcon class="slide-icon left" />
                    <ArrowUpRightIcon class="slide-icon" />
                  </div>
                </div>
              </transition>

              <!-- Slideshow 2 Images with Fade Animation -->
              <transition name="fade" mode="out-in">
                <div class="slider-images" :key="slideshow2.currentSlide">
                  <div
                    v-show="slideshow2.currentSlide === 1"
                    class="slide"
                    :style="{
                      backgroundImage: `url(/static/img/webp/platform_image89.webp)`,
                    }"
                  >
                    <div class="overlay"></div>
                    <div class="slide-link">
                      <a href="#"
                        >Learn how ML Clever enhances market trend forecasting.</a
                      >
                    </div>
                    <ChartLineIcon class="slide-icon left" />
                    <ArrowUpRightIcon class="slide-icon" />
                  </div>
                  <div
                    v-show="slideshow2.currentSlide === 2"
                    class="slide"
                    :style="{
                      backgroundImage: `url(/static/img/webp/platform_image89.webp)`,
                    }"
                  >
                    <div class="overlay"></div>
                    <div class="slide-link">
                      <a href="#"
                        >Explore how ML Clever strengthens risk management strategies.</a
                      >
                    </div>
                    <ShieldCheckIcon class="slide-icon left" />
                    <ArrowUpRightIcon class="slide-icon" />
                  </div>
                  <div
                    v-show="slideshow2.currentSlide === 3"
                    class="slide"
                    :style="{
                      backgroundImage: `url(/static/img/webp/platform_image89.webp)`,
                    }"
                  >
                    <div class="overlay"></div>
                    <div class="slide-link">
                      <a href="#">See how ML Clever combats financial fraud with AI.</a>
                    </div>
                    <LockClosedIcon class="slide-icon left" />
                    <ArrowUpRightIcon class="slide-icon" />
                  </div>
                  <div
                    v-show="slideshow2.currentSlide === 4"
                    class="slide"
                    :style="{
                      backgroundImage: `url(/static/img/webp/platform_image89.webp)`,
                    }"
                  >
                    <div class="overlay"></div>
                    <div class="slide-link">
                      <a href="#"
                        >Discover how ML Clever enhances customer personalization.</a
                      >
                    </div>
                    <UserIcon class="slide-icon left" />
                    <ArrowUpRightIcon class="slide-icon" />
                  </div>
                </div>
              </transition>
            </div>

            <!-- Slideshow 2 Navigation Buttons -->
            <div class="slider-buttons">
              <button
                :class="{ active: slideshow2.currentSlide === 1 }"
                @click="changeSlideShow2(1, 'left')"
              >
                Forecasting
              </button>
              <button
                :class="{ active: slideshow2.currentSlide === 2 }"
                @click="changeSlideShow2(2, 'right')"
              >
                Risk
              </button>
              <button
                :class="{ active: slideshow2.currentSlide === 3 }"
                @click="changeSlideShow2(3, 'right')"
              >
                Fraud
              </button>
              <button
                :class="{ active: slideshow2.currentSlide === 4 }"
                @click="changeSlideShow2(4, 'right')"
              >
                Personalization
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Stats 2 -->
    <section class="stat-section base-style">
      <div class="platform-container">
        <header>
          <h4>Unleashing AutoML</h4>
          <h2>Powerful Features to Accelerate Results</h2>
          <p>
            ML Clever’s AutoML goes beyond automation, delivering intelligent tools to
            optimize every stage of your machine learning journey from data preparation to
            deployment.
          </p>
        </header>
        <div class="stat-content">
          <div class="stat">
            <h3>Diverse Algorithm Selection</h3>
            <ul>
              <li>Over 15+ algorithms for regression, classification, and beyond</li>
              <li>
                Includes advanced models like Neural Networks and Random Forests for all
                complexity levels
              </li>
              <li>Hyperparameter optimization tailored to each algorithm’s strengths</li>
            </ul>
          </div>
          <div class="stat">
            <h3>Guided Training Workflows</h3>
            <ul>
              <li>
                Step-by-step guidance through data preparation, model selection, and
                evaluation
              </li>
              <li>Real-time monitoring for progress and performance insights</li>
              <li>Dynamic adjustments to ensure seamless model refinement</li>
            </ul>
          </div>
          <div class="stat">
            <h3>High-Speed Experimentation</h3>
            <ul>
              <li>Parallel model evaluation for faster iteration and insights</li>
              <li>
                Adaptive pipelines that fine-tune models automatically based on results
              </li>
              <li>Optimized for rapid deployment, delivering results in record time</li>
            </ul>
          </div>
          <div class="stat">
            <h3>Scalable and Flexible Infrastructure</h3>
            <ul>
              <li>Handles complex workflows with concurrent model processing</li>
              <li>Dynamic resource allocation for optimal performance</li>
              <li>Scales effortlessly to accommodate datasets of any size</li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <!-- Card Section with Images -->
    <section class="card-section with-image">
      <div class="platform-container">
        <header>
          <h4>Transformative Tools</h4>
          <h2>Empowering Financial Intelligence</h2>
          <p>
            Explore how ML Clever redefines data analytics with tools designed to enhance
            accuracy, streamline workflows, and deliver unmatched insights in the finance
            industry.
          </p>
        </header>
        <div class="card-content">
          <div class="card">
            <img
              src="/static/img/platform/work2.jpg"
              alt="Customizable Dashboards"
              class="card-img"
            />
            <div class="card-text-container">
              <h3>Custom Dashboards</h3>
              <p>
                Build and modify interactive dashboards to track KPIs, visualize trends,
                and make data-driven decisions with clarity and confidence.
              </p>
              <p class="learn-more">Learn More</p>
            </div>
          </div>
          <div class="card">
            <img
              src="/static/img/platform/work3.jpg"
              alt="Automated Pipelines"
              class="card-img"
            />
            <div class="card-text-container">
              <h3>Automated Pipelines</h3>
              <p>
                Automate data workflows and ensure real-time updates with pipeline
                refreshes and evaluation tools to maintain peak performance.
              </p>
              <p class="learn-more">Learn More</p>
            </div>
          </div>
          <div class="card">
            <img
              src="/static/img/platform/work4.jpg"
              alt="Actionable Insights"
              class="card-img"
            />
            <div class="card-text-container">
              <h3>Actionable Insights</h3>
              <p>
                Generate detailed predictions and forecasts to drive strategic financial
                decisions and stay ahead in competitive markets.
              </p>
              <p class="learn-more">Learn More</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Platform Content Section -->
    <section class="platform-content-section base-style">
      <div class="platform-container">
        <div class="platform-content">
          <div class="text-content">
            <h4>Performance Insights</h4>
            <h2>Evaluate, Understand, and Improve</h2>
            <p>
              Gain a comprehensive understanding of your model’s performance with ML
              Clever’s powerful evaluation tools. From high-level metrics to granular
              analysis, ensure your models are ready for real-world application.
            </p>
            <p>
              Delve into accuracy, precision, recall, and F1-scores while visualizing
              results with interactive ROC and PR curves, confusion matrices, and feature
              importance charts. Interpret your model’s predictions with clarity and
              confidence, driving impactful improvements.
            </p>
            <button
              class="submit-button-cls"
              @click="navigateTo('/platform/model-training')"
            >
              Discover More
            </button>
          </div>
          <div class="image-container">
            <img
              src="/static/img/platform/work8.jpg"
              alt="Interactive Model Evaluation Dashboard with Visual Insights"
            />
          </div>
        </div>
      </div>
    </section>

    <!-- Platform Content Section -->
    <section class="platform-content-section base-style">
      <div class="platform-container">
        <div class="platform-content">
          <div class="text-content">
            <h4>Empower Your Data</h4>
            <h2>No-Code AI for Every Ambition</h2>
            <p>
              Discover the easiest way to harness machine learning. Start with our entry
              plan to explore powerful features, and upgrade to unlock advanced tools
              tailored for teams, businesses, and enterprises. Build smarter, faster, and
              scale effortlessly with pricing options designed to grow with you.
            </p>
            <button class="submit-button-cls" @click="navigateTo('/auth/register')">
              Sign Up
            </button>
          </div>
          <div class="image-container stats">
            <div class="stat">
              <h3>Plans That Fit Your Needs</h3>
              <ul>
                <li>Flexible entry plan to get started</li>
                <li>Pro and Enterprise tiers for scaling businesses</li>
                <li>Custom options for unique requirements</li>
              </ul>
            </div>
            <div class="stat">
              <h3>Seamless, Automated Workflows</h3>
              <ul>
                <li>Effortless data preprocessing, training, and deployment</li>
                <li>Customizable pipelines for tailored solutions</li>
                <li>Real-time predictions and insights at your fingertips</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, defineAsyncComponent } from "vue";
import NavBar from "@/components/basic/NavBar.vue";
import GradientCanvas from "@/components/platform/gradients/GradientCanvas1.vue";
const DataPreprocessingVisual7 = defineAsyncComponent(() =>
  import("@/components/platform/visuals/DataPreprocessingVisual7")
);
const DataPreprocessingVisual = defineAsyncComponent(() =>
  import("@/components/platform/visuals/DataPreprocessingVisual")
);

import ModelVisual2 from "@/components/instances/documentation/visuals/ModelVisual2.vue";
import ModelVisual from "@/components/instances/documentation/visuals/ModelVisual.vue";
import WorkspaceVisual from "@/components/instances/documentation/visuals/WorkspaceVisual.vue";
import PredictVisual2 from "@/components/instances/documentation/visuals/PredictVisual2.vue";

import {
  CogIcon,
  ArrowUpRightIcon,
  SparklesIcon,
  BoltIcon,
  ArrowsPointingOutIcon,
  Cog6ToothIcon,
  ChartLineIcon,
  ShieldCheckIcon,
  LockClosedIcon,
  UserIcon,
  ArrowPathIcon,
} from "@heroicons/vue/24/outline";

const slideshow1 = ref({
  currentSlide: 1,
  slides: [
    {
      id: 1,
      imgSrc: "/static/img/webp/platform_image6.webp",
      title: "Effortless Model Automation",
      link: "#",
      linkText:
        "Transform the way you build machine learning models. ML Clever automates everything—from data preprocessing to deployment—ensuring unmatched speed and accuracy in your workflows.",
      buttonLabel: "Industry Automation",
      icon: Cog6ToothIcon, // Heroicons Cog6ToothIcon for automation and machinery
      color: "#1E293B", // Dark blue-gray to represent professionalism and reliability
      color_before: "#1E293B00", // 0% opacity
      color_after: "#1E293B80", // 50% opacity
      color_fullwidth: "#1E293B40", // 25% opacity
    },
    {
      id: 2,
      imgSrc: "/static/img/webp/platform_image10.webp",
      title: "Hyper-Optimized Performance",
      link: "#",
      linkText:
        "Achieve peak model performance with cutting-edge hyperparameter tuning and real-time optimization. ML Clever ensures your models are always at their best, no matter the complexity.",
      buttonLabel: "Performance Tuning",
      icon: BoltIcon, // Heroicons BoltIcon for performance and speed
      color: "#3498DB", // Electric blue for futuristic and performance-driven vibe
      color_before: "#3498DB00", // 0% opacity
      color_after: "#3498DB80", // 50% opacity
      color_fullwidth: "#3498DB40", // 25% opacity
    },
    {
      id: 3,
      imgSrc: "/static/img/webp/platform_image12.webp",
      title: "Scalable for Every Need",
      link: "#",
      linkText:
        "From startups to global enterprises, ML Clever’s AutoML platform adapts to projects of any size, delivering scalability and powerful insights for your unique challenges.",
      buttonLabel: "Scalability",
      icon: ArrowsPointingOutIcon, // Heroicons ArrowsPointingOutIcon for growth and scalability
      color: "#E67E22", // Warm orange for scalability and growth
      color_before: "#E67E2200", // 0% opacity
      color_after: "#E67E2280", // 50% opacity
      color_fullwidth: "#E67E2240", // 25% opacity
    },
    {
      id: 4,
      imgSrc: "/static/img/webp/platform_image4.webp",
      title: "Empowering Innovation Through Automation",
      link: "#",
      linkText:
        "Focus on your vision while we handle the complexity. With ML Clever, automation becomes your secret weapon for driving innovation and efficiency at every level.",
      buttonLabel: "Innovation",
      icon: SparklesIcon, // Heroicons SparklesIcon for inspiration and innovation
      color: "#8E44AD", // Purple for innovation and creativity
      color_before: "#8E44AD00", // 0% opacity
      color_after: "#8E44AD80", // 50% opacity
      color_fullwidth: "#8E44AD40", // 25% opacity
    },
  ],
  intervalId: null,
});
// Slideshow 2 Data
const slideshow2 = ref({
  currentSlide: 1,
  intervalId: null,
});

const slideshow2Direction = ref("right");

const startAutoSlideShow1 = () => {
  clearInterval(slideshow1.value.intervalId);
  slideshow1.value.intervalId = setInterval(() => {
    slideshow1.value.currentSlide =
      slideshow1.value.currentSlide < slideshow1.value.slides.length
        ? slideshow1.value.currentSlide + 1
        : 1;
  }, 10000);
};

const changeSlideShow1 = (slideId) => {
  slideshow1.value.currentSlide = slideId;
  startAutoSlideShow1();
};

const startAutoSlideShow2 = () => {
  clearInterval(slideshow2.value.intervalId);
  slideshow2.value.intervalId = setInterval(() => {
    slideshow2.value.currentSlide =
      slideshow2.value.currentSlide < 4 ? slideshow2.value.currentSlide + 1 : 1;
  }, 10000);
};

const changeSlideShow2 = (slideId, direction) => {
  slideshow2.value.currentSlide = slideId;
  slideshow2Direction.value = direction;
  startAutoSlideShow2();
};

// Preload Images
const preloadImages = () => {
  slideshow1.value.slides.forEach((slide) => {
    const img = new Image();
    img.src = slide.imgSrc;
  });
};

const navigateTo = (url) => {
  if (url) {
    window.location.href = url;
  } else {
    console.error("Navigation URL is not provided!");
  }
};

const navigateToRegister = () => {
  window.location.href = "/auth/register";
};

onMounted(() => {
  preloadImages();
  startAutoSlideShow1();
  startAutoSlideShow2();
});
onBeforeUnmount(() => {
  clearInterval(slideshow1.value.intervalId);
  clearInterval(slideshow2.value.intervalId);
});
</script>
