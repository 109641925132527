<template>
    <div class="action-menu">
        <!-- Plus Icon for opening action menu -->
        <button class="action-button" @click="toggleMenu('actions')" :aria-expanded="openMenu === 'actions'"
            aria-haspopup="true">
            <PlusIcon class="icon" />
        </button>

        <!-- Settings Gear Icon for toggles -->
        <button class="action-button" @click="toggleMenu('settings')" :aria-expanded="openMenu === 'settings'"
            aria-haspopup="true">
            <CogIcon class="icon" />
        </button>

        <!-- Notification Bell Icon for notifications -->
        <button class="action-button" @click="toggleMenu('notifications')" :aria-expanded="openMenu === 'notifications'"
            aria-haspopup="true">
            <div class="icon-wrapper">
                <BellIcon class="icon" />
                <span v-if="hasUnreadNotifications" class="notification-dot"></span>
            </div>
        </button>

        <!-- Action Links Dropdown with Transition -->
        <transition name="fade-slide-down">
            <ActionLinksDropdown v-if="openMenu === 'actions'" :action-links="actionLinks" @navigate="navigateTo" />
        </transition>

        <!-- Settings Dropdown with Transition -->
        <transition name="fade-slide-down">
            <SettingsDropdown v-if="openMenu === 'settings'" :dark-mode="darkMode"
                :border-radius-enabled="borderRadiusEnabled" @darkModeToggle="handleDarkModeToggle"
                @borderRadiusToggle="handleBorderRadiusToggle" />
        </transition>

        <!-- Notifications Dropdown -->
        <transition name="fade-slide-down">
            <NotificationsDropdown v-if="openMenu === 'notifications'" :open="openMenu === 'notifications'"
                :notifications="notifications" @markAsRead="markNotificationsAsRead" />
        </transition>
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { PlusCircleIcon as PlusIcon, CogIcon, BellIcon } from "@heroicons/vue/24/outline";
import ActionLinksDropdown from "./ActionLinksDropdown.vue";
import SettingsDropdown from "./SettingsDropdown.vue";
import NotificationsDropdown from "./NotificationBell.vue";
import axios from "@/utils/http";

// State for menus
const openMenu = ref(null);

// State for settings
const darkMode = ref(false);
const borderRadiusEnabled = ref(false);

// State for notifications
const notifications = ref([]);
const hasUnreadNotifications = ref(false);

// Lifecycle: Polling for notifications
let pollInterval = null;

const fetchNotifications = async () => {
    try {
        const response = await axios.get("/user/notifications", {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        });
        notifications.value = response.data.notifications || [];
        hasUnreadNotifications.value = notifications.value.some((n) => !n.read);
    } catch (error) {
        console.error("Failed to fetch notifications:", error);
    }
};

const startPolling = () => {
    if (!pollInterval) {
        pollInterval = setInterval(fetchNotifications, 30000); // Poll every 30 seconds
        fetchNotifications(); // Fetch immediately on mount
    }
};

const stopPolling = () => {
    if (pollInterval) {
        clearInterval(pollInterval);
        pollInterval = null;
    }
};

// Mark notifications as read
const markNotificationsAsRead = () => {
    notifications.value = notifications.value.map((n) => ({ ...n, read: true }));
    hasUnreadNotifications.value = false;
};

// Toggle menu state
const toggleMenu = (menu) => {
    openMenu.value = openMenu.value === menu ? null : menu;
};

// Lifecycle hooks
onMounted(startPolling);
onUnmounted(stopPolling);
</script>
