<template>
  <div class="navbar-container">
    <ul class="navbar-nav">
      <!-- Links for logged-out users -->

      <li :class="{ active: activeSubMenu === 'products' }" @mouseover="showSubMenu('products', $event)"
        @mouseleave="delayedHideSubMenu">
        <p>Products</p>
      </li>
      <li :class="{ active: activeSubMenu === 'company' }" @mouseover="showSubMenu('company', $event)"
        @mouseleave="delayedHideSubMenu">
        <p>Solutions</p>
      </li>
      <li :class="{ active: activeSubMenu === 'resources' }" @mouseover="showSubMenu('resources', $event)"
        @mouseleave="delayedHideSubMenu">
        <p>Resources</p>
      </li>
      <li>
        <a href="/auth/login">Login</a>
      </li>
      <li>
        <a class="cta-button" href="/auth/register">Sign Up</a>
      </li>
    </ul>

    <!-- Submenu container -->
    <teleport to="body">
      <transition name="fade-container">
        <div v-if="activeSubMenu" class="submenu-container" :style="submenuStyle" @mouseover="cancelHideSubMenu"
          @mouseleave="delayedHideSubMenu">
          <!-- Dynamic submenu content with fade-in-from-right -->
          <div class="submenu-content">
            <transition name="fade-right">
              <WorkspaceSubMenu v-if="activeSubMenu === 'workspace'" />
              <DataSubMenu v-if="activeSubMenu === 'data'" />
              <ModelsSubMenu v-if="activeSubMenu === 'models'" />
              <PipelinesSubMenu v-if="activeSubMenu === 'pipelines'" />
              <SupportSubMenu v-if="activeSubMenu === 'support'" />
              <ProductsSubMenu v-if="activeSubMenu === 'products'" />
              <CompanySubMenu v-if="activeSubMenu === 'company'" />
              <ResourcesSubMenu v-if="activeSubMenu === 'resources'" />
            </transition>
          </div>
        </div>
      </transition>
    </teleport>
  </div>
</template>

<script setup>
import { ref } from "vue";

// Import all submenu components
import DataSubMenu from "@/components/basic/navbar/DatasetSubMenu.vue";
import ModelsSubMenu from "@/components/basic/navbar/ModelsSubMenu.vue";
import PipelinesSubMenu from "@/components/basic/navbar/PipelinesSubMenu.vue";
import SupportSubMenu from "@/components/basic/navbar/DatasetSubMenu.vue"; // Adjusted import for Support
import CompanySubMenu from "@/components/basic/navbar/SolutionsSubMenu.vue";
import ResourcesSubMenu from "@/components/basic/navbar/ResourcesSubMenu.vue";
import ProductsSubMenu from "@/components/basic/navbar/ProductsSubMenu.vue";
import WorkspaceSubMenu from "@/components/basic/navbar/WorkspaceSubMenu.vue";

const activeSubMenu = ref(null);
const submenuStyle = ref({
  top: "0px",
  left: "0px",
  position: "absolute",
});

let hideTimeout = null;

const showSubMenu = (menu, event) => {
  clearTimeout(hideTimeout);
  activeSubMenu.value = menu;

  const parentLi = event.currentTarget;
  const rect = parentLi.getBoundingClientRect();

  // Adjust for scrolling using window.scrollY and window.scrollX
  submenuStyle.value = {
    top: `${rect.bottom + window.scrollY + 10}px`, // Adjust position of submenu
    left: `${rect.left + window.scrollX}px`,
    position: "absolute",
    width: `${rect.width}px`,
  };
};

const delayedHideSubMenu = () => {
  hideTimeout = setTimeout(() => {
    activeSubMenu.value = null;
  }, 200);
};

const cancelHideSubMenu = () => {
  clearTimeout(hideTimeout);
};
</script>
