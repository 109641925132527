<template>
  <div class="sidebar-container">
    <div :class="['sidebar', sidebarClass]" @transitionend="onTransitionEnd">
      <ul class="sidebar-nav">
        <!-- Core Links -->
        <li v-for="item in coreSidebarItems" :key="item.route" @click="navigateTo(item.route)"
          :class="{ 'active-tab': isActiveRoute(item.route) }" class="nav-item">
          <component :is="item.icon" class="sidebar-icon h-6 w-6" />
          <span v-if="isSidebarOpen" class="nav-text">{{ item.label }}</span>
        </li>

        <RecentShortcuts />

        <!-- Data & Models Section (Child Component) -->
        <DataModels :isSidebarOpen="isSidebarOpen" :navigateTo="navigateTo" :isActiveRoute="isActiveRoute"
          @category-clicked="handleCategoryClick" />
      </ul>

      <!-- Footer -->
      <div class="sticky-bottom">
        <div class="toggle-button" @click="toggleSidebar">
          <i class="fas" :class="isSidebarOpen ? 'fa-chevron-left' : 'fa-chevron-right'"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import axios from "@/utils/http.js";
import DataModels from "./AccordianSection.vue";
import RecentShortcuts from "./RecentShortcuts.vue";

// Import SVG files as URLs
import { HomeIcon } from "@heroicons/vue/24/outline";

// Sidebar state
const isSidebarOpen = ref(true);
const textVisible = ref(true);

// Core sidebar items
const coreSidebarItems = [{ route: "/workspace", label: "Home", icon: HomeIcon }];

// Subscription details
const subscriptionDetails = ref({ tokens: 0, storage_gb: 0 });

// Fetch subscription details
const fetchSubscriptionDetails = async () => {
  try {
    const response = await axios.get("/user/subscription_details");
    subscriptionDetails.value = response.data;
  } catch (error) {
    console.error("Failed to fetch subscription details", error);
  }
};

onMounted(() => {
  const storedSidebarState = localStorage.getItem("isSidebarOpen");
  if (storedSidebarState !== null) {
    isSidebarOpen.value = storedSidebarState === "true";
    textVisible.value = isSidebarOpen.value;
  }

  fetchSubscriptionDetails();
});

// Sidebar toggle
const toggleSidebar = () => {
  textVisible.value = !isSidebarOpen.value;
  isSidebarOpen.value = !isSidebarOpen.value;
  localStorage.setItem("isSidebarOpen", isSidebarOpen.value.toString());
};

// Navigation
const navigateTo = (route) => {
  window.location.href = route;
};

const isActiveRoute = (route) => {
  return window.location.pathname === route;
};

const handleCategoryClick = () => {
  isSidebarOpen.value = true;
};

const sidebarClass = computed(() => (isSidebarOpen.value ? "open" : "closed"));

watch(isSidebarOpen, (newVal) => {
  document.querySelector(".main-container").classList.toggle("sidebar-closed", !newVal);
});
</script>
